import 'css/main.css';
import 'typeface-roboto';

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/tripview/service-worker.js').then(registration => {
            console.log('SW registered: ', registration);
            registration.addEventListener('updatefound', () => {
                let newWorker = registration.installing;
                newWorker.addEventListener('statechange', () => {
                    // Has service worker state changed?
                    switch (newWorker.state) {
                        case 'installed':
                            // There is a new service worker available, show the notification
                            if (navigator.serviceWorker.controller) {
                                let notification = document.getElementById('reloadNotify');
                                notification.style.visibility = 'visible';
                            }
                            break;
                    }
                })
            })
        }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
